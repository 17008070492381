import { graphql } from 'gatsby';
import React from 'react'
import AuthorGrid from '../components/authors/AuthorGrid';
import PageHeader from '../components/PageHeader';
import PageSpace from '../components/PageSpace';
import Pagination from '../components/Pagination';
import SEO from '../components/SEOFile';
import AdSense from 'react-adsense';

export const AuthorsQuery = graphql`
    query authorsQuery($limit: Int!, $offset: Int!){
        allSanityAuthor(limit: $limit, skip: $offset){
            nodes{
                id
                name
                slug{
                    current
                }
                profileImage{
                    alt
                    asset{
                        gatsbyImageData
                    }
                }
            }
        }
    }
`;

function AuthorList({data, pageContext}) {
    const authors = data.allSanityAuthor.nodes;
    const {currentPage, numberOfPages} = pageContext;

    return (
        <>
            <PageSpace to={80} bottom={100}>
                <SEO title="Auctux-Authors"/>
                <div className='container'>
                    <PageHeader
                    title="Our Authors" 
                    description="Our pool of writers consists of experienced programmers and designers , all have one passion in common: to share their knowledge and experience"/>

                    <AuthorGrid authors={authors}/>
                    {
                        numberOfPages > 1 && (
                            <Pagination baseURL={'/authors'} currentPage={currentPage} numberOfPages={numberOfPages}/>
                        )
                    }
                </div>

            </PageSpace>
            <AdSense.Google
            client='ca-pub-7363395996736530'
            slot='7806394673'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
            layoutKey='-gw-1+2a-9x+5c'
            />   
        </>
    )
}

export default AuthorList
