import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import {AuthorItemStyles} from '../../styles/author/AuthorItemStyles'
import { RegularTitle } from '../typography/Title'

function AuthorItem({profileImage, name, slug}) {
    return (
        <AuthorItemStyles to={slug.current} className="author-item">
            <GatsbyImage 
            className='profileImage' 
            image={profileImage.asset.gatsbyImageData} 
            alt={profileImage.alt}/>
            <RegularTitle>{name}</RegularTitle>
        </AuthorItemStyles>
    )
}

export default AuthorItem
