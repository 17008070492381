import React from 'react'
import {AuthorGridStyles} from '../../styles/author/AuthorGridStyles'
import AuthorItem from './AuthorItem'


function AuthorGrid({authors}) {
    return (
        <AuthorGridStyles>
            {
                authors && authors.map(author => {
                    return <AuthorItem name={author.name} profileImage={author.profileImage} slug={author.slug} key={author.slug.current}/>
                })
            }
        </AuthorGridStyles>
    )
}

export default AuthorGrid
